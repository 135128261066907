//权限相关
import {frontOrBackCameras} from "r/index/position";
import {Dialog, Toast} from 'vant';
import frontImg from "a/images/index/front.png"
import backImg from "a/images/index/back.png"
import {studentStatus} from "r/index/studentStatus";

export const positionMixin = {
    data() {
        return {
            frontImg: frontImg,
            backImg: backImg,
        };
    },
    created() {
        localStorage.setItem("studentStatusTime",0);
        let exam_uuid = localStorage.getItem("monitor-subjectUuId");
        let sfzNum = localStorage.getItem("monitor-zkz");
        this.studentStatusPost(sfzNum,exam_uuid);
    },
    methods: {
        // 退出登录
        async logout() {
            Dialog.confirm({
                title: '提醒',
                message: '是否退出登录？',
            }).then(() => {
                Toast('退出成功');
                setTimeout(() => {
                    this.$store.commit("loginOut");
                    this.$router.replace('/login');
                }, 1000)
            }).catch(() => {
                // on cancel
            });

        },
        studentStatusPost(sfzNum,exam_uuid){
            var time1 = Date.parse(new Date());
            let studentStatusTime = localStorage.getItem("studentStatusTime");
            if( time1 > parseInt(studentStatusTime) + 10 || studentStatusTime == 0){
                studentStatus(sfzNum,exam_uuid).then( (res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    localStorage.setItem("studentStatusTime", time1);
                });
            }
        },
        // 正面监控/背面监控
        async selectCameras(camera) {
            const toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '选择中...',
            });
            this.$emit("initAudio");
            let {data} = await frontOrBackCameras(camera);

            toast.close();
            if (data.code == 200) {
                data = data.data;
                localStorage.setItem("monitor-examUuid", data.examUuid);
                localStorage.setItem("monitor-camera", camera);
                localStorage.setItem("monitor-has_step", data.needUpload);
                localStorage.setItem("monitor-steps", data.steps);
                localStorage.setItem("monitor-videoSize", data.videoSize);

                if (camera == 'front') {
                    let huanjing = localStorage.getItem("monitor-huanjing");
                    //let huanjing =  1;
                    let renzheng = localStorage.getItem("monitor-renzheng");
                    let isVerify = localStorage.getItem("monitor-isVerify");
                    let chengnuoshu = localStorage.getItem("monitor-chengnuoshu");
                    let is_xuzhi = localStorage.getItem("is_xuzhi");

                    if(chengnuoshu == 1 && huanjing == 1 && renzheng == 1){//承诺书==1  环境==1  认证==1

                        await this.$router.replace('/monitor');
                    }else if (isVerify == 1 && renzheng == 0) {  //isVerify == 1  认证==0

                        await this.$router.replace({path: '/camera'});
                    } else if (renzheng == 1 && huanjing == 0) {   //认证 == 1  环境==0

                        await this.$router.replace({path: '/envir'});
                    }else if(huanjing == 1 && chengnuoshu == 0){   //环境 == 1  承诺书==0

                        await this.$router.replace({path: '/promise'});
                    /*}else if(!is_xuzhi){   //须知false

                        await this.$router.replace({path: '/detail'});  清华附中-民乐 不需要须知 baiansen */
                    }
                } else {
                    await this.$router.replace({path: '/monitor'});
                }
                // let steps = 3;
                // let has_step = 1;
                // if(data.needUpload == 0){
                //   data.needUploadStep = 0;
                //   has_step = 0;
                // }else {
                //   steps = data.steps.split(',');
                //   let str = 0;
                //   for (let i = 0;i<steps.length;i++){
                //     if(steps[i] == 1){
                //       ++str;
                //     }
                //   }
                //   steps = str;
                // }
                // localStorage.setItem("monitor-step", data.needUploadStep ? data.needUploadStep : 0 );
                // if(has_step != 0){
                //   await this.$router.replace({path: '/'});
                // }else {
                //   await this.$router.replace({path: '/monitor'});
                // }
            } else {
                Dialog.alert({
                    message: data.message
                }).then(() => {
                    this.$store.commit("loginOut");
                    this.$router.replace('/login');
                });
            }
        },
    },
    activated() {

    },
};
