<template>
    <div class="position">
        <div>
            <div class="logout" @click="logout">退出</div>

            <h2 style="margin-top: 10%">视频监控</h2>
            <div class="txt-1">请选择需要开启的监控类型</div>
            <div class="txt-2">*考试报到阶段，请考生先选择【电脑监控】</div>
            <!--<h2>视频监控</h2>
            <p>请先选择设备所处监控位置</p>
            <div>
                <h3>什么是正面监控？</h3>
                <p>正面监控放置于考生左前方或右前方，照向考生的上半身和手部动作。</p>
            </div>
            <div>
                <h3>什么是背面监控？</h3>
                <p>背面监控放置于考生左后方或右后方，照向考生背面及手部动作。</p>
            </div>-->
        </div>
        <div>
            <div @click="selectCameras('front')">
                <van-row>
                    <van-col span="3" class="camera-img">
                        <van-image :src="frontImg"></van-image>
                    </van-col>
                    <van-col span="20">手机监控</van-col>
                </van-row>
            </div>
            <div @click="selectCameras('back')">
                <van-row>
                    <van-col span="3" class="camera-img">
                        <van-image :src="backImg"></van-image>
                    </van-col>
                    <van-col span="20">电脑监控</van-col>
                </van-row>
            </div>
        </div>
    </div>
</template>

<script>
    import {positionMixin} from "mixins/index/position";
    import {Col, Row} from 'vant';

    export default {
        name: "position",
        mixins: [positionMixin],
        data() {
            return {};
        },
        methods: {},
    }
</script>

<style scoped lang="scss">
    .position {
        max-width: 1024px;
        margin:0 auto;

        width: 94%;
        height: 100%;
        padding-top: 16%;
        margin: 0 auto;

        .txt-1 {
            font-size: 16px;
            color: #000000;
        }

        .txt-2 {
            color: #563279;
            font-size: 14px;
        }

        > div {
            &:first-child {
                position: relative;
                color: #333;
                font-weight: 400;

                > h2 {
                    font-size: 24px;
                    font-family: Times New Roman;
                }

                > p {
                    font-size: 17px;
                    font-family: PingFang SC;
                    margin-top: 10px;
                }

                > .logout {
                    position: absolute;
                    right: 20px;
                    top: -25%;
                    margin: 0;
                    color: #8b2d87;
                    font-size: 16px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &:last-child {
                margin-top: 10%;
                margin-bottom: 20%;

                > div {
                    width: 100%;
                    padding: 20px;
                    margin-top: 20px;
                    border-radius: 6px;
                    color: #fff;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;

                    &:first-child {
                        background: #563279;
                    }

                    &:last-child {
                        background: #8B2D87;
                    }

                    &.existCamera {
                        background: #C6C6C6;
                    }
                }
            }

            > div {
                &:first-child {
                    margin-top: 20px;
                }

                margin-top: 15px;

                > h3 {
                    font-weight: normal;
                    font-size: 14px;
                }

                > p {
                    margin-top: 5px;
                    font-size: 12px;
                    color: #666;
                }
            }
        }

        .van-col--20 {
            margin-top: 3px;
        }
    }
</style>
<style lang="scss">
    .position {
        .mu-input__focus {
            color: #8b2d87;
        }

        .van-dialog__confirm, .van-dialog__confirm:active {
            color: #8b2d87;
        }

        .van-image__img {
            width: 30px;
            height: 30px;
        }
    }

</style>
